import React, { useState, useEffect } from "react";
import $ from "jquery";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

const WinnerDemo = () => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="demo-bg gradient-bg">
        <div className="demo-duration"><h3>Duration</h3><p>1 Month</p></div>
        <div class="demo-prizes">
          <h3>Prizes</h3>
          <p className="demo-prizes-list">
            <span><sup> 1ST </sup>
              $500</span>
            <span><sup> 2ND </sup>
              $300</span>
            <span><sup> 3RD </sup>
              $100</span>
            <span><sup> 4TH </sup>
              $50</span>
            <span><sup> 5TH </sup>
              $50</span>
          </p>
        </div>
        <div className="demo-duration"><h3>Platform</h3><p>MT5</p></div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(WinnerDemo);